import React from 'react';
import ReactECharts from 'echarts-for-react';

const PennyDropPieChart = ({ data }) => {
  // Calculate totals for KARZA, CASHFREE, and null
  const karzaTotal = data.reduce((acc, item) => {
    if (item.api_client_name === 'KARZA_API') {
      return acc + (item["Total Success (S)"] || 0) + (item["Total Failed (J)"] || 0) + (item.Blank || 0);
    }
    return acc;
  }, 0);

  const cashfreeTotal = data.reduce((acc, item) => {
    if (item.api_client_name === 'CASHFREE_API') {
      return acc + (item["Total Success (S)"] || 0) + (item["Total Failed (J)"] || 0) + (item.Blank || 0);
    }
    return acc;
  }, 0);

  const tatacapitalFinancial = data.reduce((acc,item) => {
    if (item.company_code === '7000') {
      return acc + (item["Total Success (S)"] || 0) + (item["Total Failed (J)"] || 0) + (item.Blank || 0);
    }
    return acc;
  },0);


  const tatacapitalHousing = data.reduce((acc,item) => {
    if (item.company_code === '9000') {
      return acc + (item["Total Success (S)"] || 0) + (item["Total Failed (J)"] || 0) + (item.Blank || 0);
    }
    return acc;
  },0);
  // const nullTotal = data.reduce((acc, item) => {
  //   if (item.api_client_name === null) {
  //     return acc + (item["Total Success (S)"] || 0) + (item["Total Failed (J)"] || 0) + (item.Blank || 0);
  //   }
  //   return acc;
  // }, 0);

  const totalRegistered = karzaTotal + cashfreeTotal 
  // + nullTotal;

  // Total counts summary for the bottom right
  const totalCounts = [
    { name: 'KARZA', total: karzaTotal, color: '#1E3A8A' },
    { name: 'CASHFREE', total: cashfreeTotal, color: '#2563EB' },
    { name: '7000', total: tatacapitalFinancial, color: '#60A5FA'},
    { name: '9000', total: tatacapitalHousing, color: '#93C5FD'}
    // { name: 'Null', total: nullTotal, color: '#FFCC00' },
  ];

  const chartOptions = {
    title: {
      text: "Total count by vendor",
      left: "left",
      // top: 1, // Adjusted top value for spacing
      textStyle: {
        color: "#36454F",
        fontSize: 14,
        fontWeight: "bold",
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: (params) => {
        const { name, value } = params;
        const percentage = ((value / totalRegistered) * 100).toFixed(2);
        return `${name}: ${value} (${percentage}%)`;
      },
    },
    legend: {
      orient: "horizontal",
      left: "left",
      top: 40, // Adjusted top value for spacing below title
      itemGap: 10,
      itemWidth: 20,
      itemHeight: 14,
      padding: [0, 0, 0, 10], // Adds padding to position the legend better
      textStyle: {
        color: "#36454F",
        fontWeight: "bold",
        fontSize: 12,
      },
    },
    series: [
      {
        name: 'Data',
        type: 'pie',
        radius: ['40%', '70%'],
        center: ['50%', '70%'],
        startAngle: 180,
        endAngle: 360,
        data: [
          { value: karzaTotal, name: 'KARZA' },
          { value: cashfreeTotal, name: 'CASHFREE' },
          { value: tatacapitalFinancial, name: '7000'},
          { value: tatacapitalHousing, name:'9000'}
          // { value: nullTotal, name: 'Null' },
        ],
        itemStyle: {
          // normal: {
          //   color: (params) => {
          //     const colors = ['#1E3A8A', '#2563EB', '#60A5FA' , '#93C5FD']; //'#FFCC00' Custom colors for KARZA, CASHFREE, and Null
          //     return colors[params.dataIndex];
          //   },
          // },
          avoidLabelOverlap: true,
          borderRadius: 4,
          borderWidth: 2,
          borderColor: '#fff'
        },
        // emphasis: {
        //   itemStyle: {
        //     shadowBlur: 10,
        //     shadowOffsetX: 0,
        //     shadowColor: 'rgba(0, 0, 0, 0.5)',
        //   },
        // },
      },
    ],
    graphic: [
      {
        type: "text",
        left: "center",
        top: "60%",
        style: {
          text: `${totalRegistered}`,
          fontSize: 18,
          fontWeight: "bold",
          textAlign: "center",
          textVerticalAlign: "middle",
          lineHeight: 30,
          color: "#36454F",
        },
      },
    ],
    color: ['#1E3A8A', '#2563EB', '#60A5FA', '#93C5FD'],
    animation: true,
    animationDuration: 1000,
    animationEasing: 'cubicInOut',
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
      <div style={{ flex: 1 }}>
        <ReactECharts option={chartOptions} style={{ height: '515px', width: '100%' }} />
      </div>
      <div
        style={{
          padding: '10px',
          backgroundColor: 'inherit',
          borderTop: '1px solid #ddd',
          display: 'flex',
          flexDirection: 'column',
        }}
        className='px-4 py-2'
      >
        <div style={{ color: '#36454F', fontSize: '16px', fontWeight: '600' }} className='mb-2'>
          Total Counts:
        </div>
        <div className='d-flex gap-2 flex-wrap'>

        {totalCounts.map((item, index) => (
          <div
          key={index}
          style={{
            marginBottom: '5px',
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px'
          }}
          className='mr-2'
          >
            <div
              style={{
                width: '10px',
                height: '10px',
                backgroundColor: item.color,
                display: 'inline-block',
                marginRight: '5px',
                borderRadius: '50%',
              }}
              ></div>
            <span
              style={{ fontWeight: '500', color: '#808080' }}
              >
              {item.name}:
            </span>
            <span style={{ color: '#000000', fontWeight: '700', marginLeft: '5px' }}>
              {item.total}
            </span>
          </div>
        ))}
        </div>
      </div>
    </div>
  );
};

export default PennyDropPieChart;
