import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    paymentMode: '',
};

const paymentModeSlice = createSlice({
    name: 'paymentMode',
    initialState,
    reducers: {
        setPaymentMode: (state, action) => {
            state.paymentMode = action.payload;
        },
    },
});

export const { setPaymentMode } = paymentModeSlice.actions;

export default paymentModeSlice.reducer;
