import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { SlideBarReducer } from "./reducer/sideBarStatus";
import loginReducer from "./reducer/login";
import { SingleMandate } from "./reducer/SingleMandateReducer";
import { AccountValidation } from "./reducer/accountValidationReducer";
import {dateTime } from "./reducer/dateTimeReducer";
import { webSocketReducer } from "./reducer/webSocketReducer";
import paymentModeReducer from './reducer/paymentModeReducer'
// import {webSocketReducer } from "./reducer/webSocketReducer";
const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: [
    // "login",
    "dateTimeReducer"
  ],
};

const reducer = combineReducers({
  login: loginReducer,
  slideBar: SlideBarReducer.showSlide,
  SingleMandate: SingleMandate,
  AccountValidation: AccountValidation,
  dateTimeReducer:dateTime,
  webSocketReducer:webSocketReducer,
  paymentMode: paymentModeReducer
});

const rootReducer = (state, action) => {
  if (action.type === "login/USER_LOGOUT") {
    storage.removeItem("persist:root");
    return reducer(undefined, action);
  }
  return reducer(state, action);
};

// const store = configureStore({
// 	reducer: loginreducer,
// 	// middleware,
// 	// enhancers: [reduxBatch]
const persistedReducer = persistReducer(persistConfig, rootReducer);

// });

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: true,
    }),
});
/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
// export const persistor = persistStore(store);

export default store;
