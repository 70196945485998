import { useEffect, useRef } from 'react';

export const useIntersectionObserver = ({callback, threshold = 0.2, rootMargin = '50px'}) => {
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          callback();
          observer.disconnect();
        }
      },
      {
        threshold,
        rootMargin,
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ callback, threshold, rootMargin]);

  return elementRef;
};