import React from "react";
import ReactECharts from "echarts-for-react";

const EnachPieChart = ({ data, filterMode }) => {
  // Aggregate total counts by auth_mode
  const authModeCounts = data.reduce((acc, item) => {
    // const mode = item.auth_mode || "Null"; // Use "Unknown" for null auth_modes
    const mode = item[filterMode];

    if(mode) {
      acc[mode] = (acc[mode] || 0) + (item["TotalHits"] || 0);
    }
    
    return acc;
  }, {});

  // Convert aggregated counts to an array of objects for the pie chart
  const pieData = Object.keys(authModeCounts).map((mode) => ({
    name: mode,
    value: authModeCounts[mode],
  }));

  const totalCounts = pieData.reduce((sum, item) => sum + item.value, 0);

  // Define chart options
  const chartOptions = {
    title: {
      text: `Total Counts by ${filterMode === 'auth_mode' ? 'Authentication Mode' : 'Source System'}`,
      left: "left",
      top: 0,
      textStyle: {
        color: "#36454F",
        fontSize: 14,
        fontWeight: "bold",
      },
    },
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        const { name, value } = params;
        const percentage = ((value / pieData.reduce((sum, item) => sum + item.value, 0)) * 100).toFixed(2);
        return `${name}: ${value} (${percentage}%)`;
      },
    },
    legend: filterMode === 'source_system' ? {show: false} : {
      orient: "horizontal",
      left: "left",
      top: 30,
      itemGap: 10,
      itemWidth: 20,
      itemHeight: 14,
      padding: [0, 0, 20, 10], // Increased bottom padding
      textStyle: {
        color: "#36454F",
        fontWeight: "bold",
        fontSize: 12,
      },
    },
    series: [{
        name: "Auth Mode",
        type: "pie",
        center: filterMode === 'source_system' ? ["50%", "54%"] : ['50%', '70%'], // Moved the center down
        radius: ["40%", "70%"], // Adjusted the radius for better positioning
        data: pieData,
        startAngle: filterMode === 'source_system' ? 0 : 180,
        endAngle: 360,
        itemStyle: {
          borderRadius: 4,
          borderWidth: 2,
          borderColor: "#fff",
          // normal: {
          //   color: (params) => {
          //     const colors = ["#1E3A8A", "#2563EB", "#60A5FA", "#93C5FD", "#CBD5E1"];
          //     return colors[params.dataIndex % colors.length];
          //   },
          // },
        },
        // emphasis: {
        //   itemStyle: {
        //     shadowBlur: 10,
        //     shadowOffsetX: 0,
        //     shadowColor: "rgba(0, 0, 0, 0.5)",
        //   },
        // },
      },
    ],
    graphic: [
      {
        type: "text",
        left: "center",
        top: filterMode === 'auth_mode' ? "60%" : '50%',
        style: {
          text: `${totalCounts}`,
          fontSize: 18,
          fontWeight: "bold",
          textAlign: "center",
          textVerticalAlign: "middle",
          lineHeight: 30,
          color: "#36454F",
        },
      },
    ],
    // color: ["#1E3A8A", "#2563EB", "#60A5FA", "#93C5FD", "#CBD5E1"],
    color: ["#1E3A8A", "#2563EB", "#60A5FA", "#93C5FD", "#CBD5E1"],
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <div style={{ flex: 1, height: 'auto' }}>
        <ReactECharts
          option={chartOptions}
          style={{ height: "500px", width: "100%" }}
        />
      </div>
      { filterMode === 'auth_mode' ? <div
        style={{
          backgroundColor: "inherit",
          borderTop: "1px solid #ddd",
        }}
        className="px-4 py-2"
      >
        <div className="d-flex flex-column">
          <div
            style={{ color: "#36454F", fontSize: "16px", fontWeight: "600" }}
            className="mb-3"
          >
            Total Counts
          </div>
          <div className="d-flex gap-2 flex-wrap">
            {pieData.map((item, index) => (
              <div
                key={index}
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  alignItems: "center",
                  fontSize: '14px'
                }}
                className="mr-2"
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: chartOptions.color[index % chartOptions.color.length],
                    display: "inline-block",
                    marginRight: "5px",
                    borderRadius: "50%",
                  }}
                ></div>
                <span style={{ fontWeight: "500", color: "#808080" }}>
                  {item.name}:
                </span>
                <span
                  style={{
                    color: "#000000",
                    fontWeight: "700",
                    marginLeft: "5px",
                  }}
                >
                  {item.value}
                </span> 
              </div>
            ))}
          </div>
        </div>
      </div> : <></>}
    </div>
  );
};

export default EnachPieChart;
