import React from 'react';
import ReactECharts from 'echarts-for-react';

const EnachChart = ({ grandTotals, authModeData }) => {
  // Extract grand total values
  const totalSuccess = grandTotals["grandTotalSuccess"];
  const totalFailed = grandTotals["grandTotalFailed"];
  const totalAborted = grandTotals["grandTotalAborted"];
  const totalPending = grandTotals["grandTotalPending"];
  const totalRegistered = grandTotals["grandTotalHits"];

  // Professional monochromatic blue palette
  
  const colors = {
    success: '#1E3A8A',    // Darkest blue - for success (most important)
    failed: '#2563EB',     // Dark blue - for failed
    pending: '#60A5FA',    // Medium-dark blue - for pending
    registered: '#93C5FD', // Medium blue - for registered
    aborted: '#CBD5E1'     // Light blue - for aborted (least emphasis)
  };
  // const colors = {
  //   success: '#1A237E',    // Darkest blue - for success (most important)
  //   failed: '#283593',     // Dark blue - for failed
  //   pending: '#3949AB',    // Medium-dark blue - for pending
  //   registered: '#5C6BC0', // Medium blue - for registered
  //   aborted: '#9FA8DA'     // Light blue - for aborted (least emphasis)
  // };

  // Data for the donut charts
  const pieData = [
    { value: totalSuccess, name: 'Success', itemStyle: { color: colors.success } },
    { value: totalFailed, name: 'Failed', itemStyle: { color: colors.failed } },
    { value: totalPending, name: 'Pending', itemStyle: { color: colors.pending } },
    { value: totalRegistered, name: 'Total Registered', itemStyle: { color: colors.registered } },
    { value: totalAborted, name: 'Aborted', itemStyle: { color: colors.aborted } }
  ];

  // Extract counts by auth_mode for tooltips
  const getAuthModeBreakdown = (type) => {
    return authModeData.reduce((acc, item) => {
      if (item.auth_mode) {
        switch(type) {
          case 'Success': acc[item.auth_mode] = item.TotalSuccess; break;
          case 'Failed': acc[item.auth_mode] = item.TotalFailed; break;
          case 'Pending': acc[item.auth_mode] = item.TotalPending; break;
          case 'Aborted': acc[item.auth_mode] = item.TotalAborted; break;
          case 'Total Registered':
            acc[item.auth_mode] = (item.TotalSuccess || 0) + (item.TotalFailed || 0) + 
                                 (item.TotalPending || 0) + (item.TotalAborted || 0);
            break;
        }
      }
      return acc;
    }, {});
  };

  // Chart options for ECharts

  const options = {
    title: {
      text: "Total counts",
      left: true,
      top: 0,
      textStyle: {
        color: "#000",  // Darkest blue for title
        fontSize: 14,
        fontWeight: "bold",
      },
    },
    tooltip: {
      trigger: 'item',
      // formatter: function(params) {
      //   const authModeBreakdown = getAuthModeBreakdown(params.name);
      //   const breakdown = Object.entries(authModeBreakdown)
      //     .map(([authMode, count]) => 
      //       `<div style="padding: 5px; color: #1A237E;">
      //         ${authMode}: <span>${count}</span>
      //       </div>`)
      //     .join('');

      //   const percentage = ((params.value / totalRegistered) * 100).toFixed(1);
        
      //   return `<div style="font-weight: bold; padding: 5px; color: #1A237E;">
      //             ${params.name}: ${params.value} (${percentage}%)
      //           </div>
      //           ${breakdown}`;
      // },
      backgroundColor: 'rgba(255, 255, 255, 0.98)',
      borderWidth: 1,
      borderColor: '#C5CAE9',  // Light blue border
      extraCssText: 'border-radius: 5px; padding: 10px; box-shadow: 0 2px 8px rgba(26, 35, 126, 0.1);'
    },
    legend: {
      orient: 'horizontal',
      top: '5%',  // Moved legend to top
      left: 'center',
      itemGap: 10,
      itemWidth: 20,
      itemHeight: 14,
      textStyle: {
        color: '#000'
      },
      
    },
    series: [{
      name: 'Status',
      type: 'pie',
      radius: ['40%', '70%'],
      center: ['50%', '70%'],
      startAngle: 180,
      endAngle: 360,
      avoidLabelOverlap: true,
      itemStyle: {
        borderRadius: 4,
        borderWidth: 2,
        borderColor: '#fff'
      },
      // label: {
      //   show: true,
      //   formatter: '{d}%',
      //   position: 'outer',
      //   fontSize: 12,
      //   color: '#1A237E'  // Darkest blue for labels
      // },
      labelLine: {
        show: true,
        length: 15,
        length2: 10,
        lineStyle: {
          color: '#9FA8DA'  // Light blue for label lines
        }
      },


      data: pieData
    }],
    graphic: [
      {
        type: "text",
        left: "center",
        top: "60%",
        style: {
          text: `${totalRegistered}`,
          fontSize: 18,
          fontWeight: "bold",
          textAlign: "center",
          textVerticalAlign: "middle",
          lineHeight: 30,
          color: "#36454F",
        },
      },
    ],
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
      <div style={{ flex: 1 }}>
        <ReactECharts option={options} style={{ height: '500px', width: '100%' }} />
      </div>
      <div style={{ padding: '16px', backgroundColor: 'inherit', borderTop: '1px solid #C5CAE9' }}>
        <div className="d-flex flex-column">
          <div style={{ fontSize: '16px', fontWeight: '600' }} className='mb-3'>
            Total Counts
          </div>
          <div className='d-flex gap-2 flex-wrap'>
            {pieData.map((item, index) => (
              <div key={index} style={{ marginBottom: '5px', fontSize: '14px' }} className="d-flex align-items-center mr-3">
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: item.itemStyle.color,
                    display: 'inline-block',
                    marginRight: '5px',
                    borderRadius: '50%',
                  }}
                />
                <span style={{ fontWeight: '500', color: '#808080' }}>
                  {item.name}:
                </span>
                <span style={{ color: '#000', fontWeight: '700', marginLeft: '5px' }}>
                  {item.value}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnachChart;

