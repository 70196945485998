import React from 'react';
import ReactECharts from 'echarts-for-react';

const PaymentCounts = ({ paymentCounts }) => {
  // Extract payment count values
  const success = paymentCounts["Success"];
  const failed = paymentCounts["Failed"];
  const awaitingFor1stApiResponse = paymentCounts["AWAITING_FOR_1ST_API_RESPONSE"];
  const underProcessing = paymentCounts['Under_Processing'];

  // Professional color palette
  const colors = {
    success: '#1E3A8A',    // Darkest blue
    failed: '#2563EB',     // Dark blue
    processing: '#60A5FA', // Medium blue
    awaiting: '#93C5FD'    // Light blue
  };

  // Data for the donut chart
  const pieData = [
    { value: success, name: 'Success', itemStyle: { color: colors.success } },
    { value: failed, name: 'Failed', itemStyle: { color: colors.failed } },
    { value: underProcessing, name: 'Under Processing', itemStyle: { color: colors.processing } },
    { value: awaitingFor1stApiResponse, name: 'Awaiting For 1st Api Response', itemStyle: { color: colors.awaiting } }
  ];

  // Calculate total for center text
  const total = pieData.reduce((sum, item) => sum + item.value, 0);

  // Chart options for ECharts
  const options = {
    title: {
      text: "Payment Counts",
      left: 'left',
      top: 0,
      textStyle: {
        color: "#36454F",
        fontSize: 14,
        fontWeight: "bold",
      },
    },
    tooltip: {
      trigger: 'item',
      backgroundColor: 'rgba(255, 255, 255, 0.98)',
      borderWidth: 1,
      borderColor: '#C5CAE9',
      extraCssText: 'border-radius: 5px; padding: 10px; box-shadow: 0 2px 8px rgba(26, 35, 126, 0.1);'
    },
    legend: {
      orient: 'horizontal',
      top: 25,
      left: 'left',
      itemGap: 20,
      textStyle: {
        color: '#36454F'
      }
    },
    series: [{
      name: 'Payment Status',
      type: 'pie',
      radius: ['40%', '70%'],
      center: ['50%', '70%'],
      startAngle: 180,
      endAngle: 360,
      avoidLabelOverlap: true,
      itemStyle: {
        borderRadius: 4,
        borderWidth: 2,
        borderColor: '#fff'
      },
      label: {
        show: true,
        position: 'outside',
        formatter: '{b}: {c}',
        color: '#36454F'
      },
      labelLine: {
        show: true,
        length: 15,
        length2: 10,
        lineStyle: {
          color: '#9FA8DA'
        }
      },
      data: pieData
    }],
    graphic: [
      {
        type: "text",
        left: "center",
        top: "60%",
        style: {
          text: `${total}`,
          fontSize: 18,
          fontWeight: "bold",
          textAlign: "center",
          textVerticalAlign: "middle",
          lineHeight: 30,
          color: "#36454F",
        },
      },
    ],
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
      <div style={{ flex: 1 }}>
        <ReactECharts option={options} style={{ height: '500px', width: '100%' }} />
      </div>
      <div style={{ padding: '16px', backgroundColor: 'inherit', borderTop: '1px solid #C5CAE9' }}>
        <div className="d-flex flex-column">
          <div style={{ color: '#36454F', fontSize: '16px', fontWeight: '600' }} className='mb-3'>
            Payment Counts
          </div>
          <div className='d-flex gap-2 flex-wrap'>
            {pieData.map((item, index) => (
              <div 
                key={index} 
                style={{ marginBottom: '5px', fontSize: '14px' }} 
                className="d-flex align-items-center mr-3"
              >
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: item.itemStyle.color,
                    display: 'inline-block',
                    marginRight: '5px',
                    borderRadius: '50%',
                  }}
                />
                <span style={{ fontWeight: '500', color: '#808080' }}>
                  {item.name}:
                </span>
                <span style={{ color: '#000', fontWeight: '700', marginLeft: '5px' }}>
                  {item.value}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentCounts;