import React, { useEffect, useState, useRef, useCallback } from "react";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { toast } from "react-toastify";
import { toastIds } from "../../../constants/Common/apiConstants";
import { errorMessages } from "../../../constants/Common/errorConstants";
import moment from "moment";
import { formatDate, buildQueryParamsString } from "../../../utils/utils";
import PaymentHeader from "../../../component/reports/PaymentDashboard/PaymentHeader";
import PaymentCounts from "../../../component/reports/PaymentDashboard/PaymentCounts";
import PaymentPieChart from "../../../component/reports/PaymentDashboard/PaymentPieChart";
import { useIntersectionObserver } from "../../../utils/IntersectionObserver";
const PaymentDashboardReport = () => {
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({})
  

  // fetch data only when the dashboard component is visible in the viewport
  const handleIntersection = useCallback(() => {
    const data = {
      FromDate: formatDate(new Date()),
      ToDate: formatDate(new Date()),
    };
    
    const queryString = buildQueryParamsString(data);
    fetchPaymentReportData(queryString);
  }, [])

  const containerRef = useIntersectionObserver({callback: handleIntersection})

  const onSubmit = async (data) => {

    if (moment(data["ToDate"], 'DD-MM-YYYY') < (moment(data["FromDate"], 'DD-MM-YYYY'))) {
      toast.error(errorMessages.toDateMustBeGreaterThanToDate, {
        toastId: toastIds?.DataError,
      });
      return;
    }

    const queryString = buildQueryParamsString(data)

    await fetchPaymentReportData(queryString);
  };

  const fetchPaymentReportData = async (queryString) => {
    setLoading(true);

    let jsonResponse;

    try {
      const res = await fetch(
        `https://apps72.tatacapital.com/reports/DashbordReport/DashboardPaymentReport${queryString}`
      );
      
      if (!res.ok) {
        throw Error;
      }

      jsonResponse = await res.json();


      setPaymentData(jsonResponse.response || {})

    } catch (error) {

      setPaymentData({})
      if(jsonResponse?.message !== 'No Data Found') {

        toast.error(jsonResponse?.message || errorMessages.SOMETHING_WRONG, {
          toastId: toastIds?.DataError,
        });
      }
    } finally {
        setLoading(false);
    }
  };

  const DisplayChart = () => {
    if (loading) {
      return (
        <div>
          <SystemXLoader />
        </div>
      );
    }

    if (Object.keys(paymentData).length === 0) {
      return <div className="mt-5 text-center h-100 d-flex align-items-center">No data found</div>;
    }

    return (
      <div
        className="flex-grow-1 d-grid mt-3"
        style={{
          gridTemplateColumns: "1fr 1fr",
          gridTemplateRows: "1fr",
          maxWidth: '1500px',
          gap: "1rem",
        }}
      >
        <div className="border shadow-sm rounded p-2">
          <PaymentCounts paymentCounts={paymentData.data[0]}/>
        </div>
        <div className="border shadow-sm rounded p-2">
          <PaymentPieChart paymentCounts={paymentData.data[0]}/>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div >
        <PaymentHeader onSubmit={onSubmit} loading={loading}/>
        
      </div>
      <div className="px-4 d-flex justify-content-center align-items-center" style={{minHeight: '630px'}} ref={containerRef}>
          <DisplayChart />
      </div>
    </div>
  );
};

export default PaymentDashboardReport;
