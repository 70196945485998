import React, { useEffect, useState } from "react";
// import clsx from 'clsx';
import "./dynamicForm.scss";
import { useParams } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Dropzone from "react-dropzone";
import { ReactComponent as FileIcon } from "../../../Images/file.svg";
import Download from "../../../Images/download.svg";
import Select, { components } from "react-select";
import { useDispatch } from "react-redux";
import { setPaymentMode } from "../../../redux/reducer/paymentModeReducer";
const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
    height: "48px"
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} />
      {children}
    </components.Option>
  );
};




function DynamicForm(props) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [formArray, setFormArray] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [clearForm, setClearForm] = useState(false);
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPaymentMode(''))
  }, [dispatch])

  const onDrop = (file) => {
    setFileData(file);
    props.onFileUpload(file);
    // handleDrop(acceptedFiles);
  };
  const clearFormData = () => {
    return formArray.map((item) => {
      var temp = Object.assign({}, item);
      if (temp.value != "") {
        temp.value = "";
      }
      return temp;
    });
  };
  const renderForm = () => {
    if (formArray?.length > 0) {
      return formArray.map((item, index) => {
        if (item?.type === "textbox" || item?.type === "number") {
          return (
            <Col lg={item?.width || 12} md={12} sm={12}>
              <div className="inputDiv mt-1">
                <p>
                  {item?.label}
                  {item?.required && <span className="textLabelRed">*</span>}
                </p>
                <Form.Control
                  type={item?.type}
                  placeholder={item?.placeholder}
                  className={`textInput ${item?.expandInput ? 'w-75': ''}`}
                  value={item?.value}
                  onChange={(e) => {
                    let tempForm = [...formArray];
                    tempForm[index].value = e.target.value;
                    item.required &&
                      (e.target.value
                        ? (tempForm[index].error = false)
                        : (tempForm[index].error = true));
                    setFormArray([...tempForm]);
                  }}
                />
                {item.error && (
                  <p className="textLabelRed m-0 mt-2">
                    {(item?.label || "This field") + " is required"}
                  </p>
                )}
              </div>
            </Col>
          );
        }
        if (item?.type === "textarea") {
          return (
            <Col lg={item?.width || 12} md={12} sm={12}>
              <div className="inputDiv mt-1">
                <p>
                  {item?.label}
                  {item?.required && <span className="textLabelRed">*</span>}
                </p>
                <Form.Control
                  as={item?.type}
                  placeholder={item?.placeholder}
                  className="textInput"
                  value={item?.value}
                  onChange={(e) => {
                    let tempForm = [...formArray];
                    tempForm[index].value = e.target.value;
                    item.required &&
                      (e.target.value
                        ? (tempForm[index].error = false)
                        : (tempForm[index].error = true));
                    setFormArray([...tempForm]);
                  }}
                />
                {item.error && (
                  <p className="textLabelRed m-0 mt-2">
                    {(item?.label || "This field") + " is required"}
                  </p>
                )}
              </div>
            </Col>
          );
        }
        if (item?.type === "dateinput") {
          return (
            <Col lg={item?.width || 12} md={12} sm={12}>
              <div className="inputDiv mt-1">
                <p>
                  {item?.label}
                  {item?.required && <span className="textLabelRed">*</span>}
                </p>
                <ReactDatePicker
                  className="customDatepicker"
                  selected={item.value}
                  dateFormat={"dd/MM/yyyy"}
                  onChange={(date) => {
                    let tempForm = [...formArray];
                    tempForm[index].value = date;
                    item.required &&
                      (date
                        ? (tempForm[index].error = false)
                        : (tempForm[index].error = true));
                    setFormArray([...tempForm]);
                  }}
                  maxDate={item.minDate ? "" : new Date()}
                  minDate={item.minDate ? new Date() : ""}
                  placeholderText={item?.placeholder}
                />
              </div>
              {item.error && (
                <p className="textLabelRed m-0 mt-2">
                  {(item?.label || "This field") + " is required"}
                </p>
              )}
            </Col>
          );
        }
        if (item?.type === "timeinput") {
          return (
            <Col lg={item?.width || 12} md={12} sm={12}>
              <div className="inputDiv mt-1">
                <p>
                  {item?.label}
                  {item?.required && <span className="textLabelRed">*</span>}
                </p>
                <input
                  type="time"
                  className="customTimepicker"
                  value={item.value}
                  onChange={(event) => {
                    const { value } = event.target;
                    let tempForm = [...formArray];
                    tempForm[index].value = value;
                    setFormArray([...tempForm]);
                  }}
                  placeholder={item?.placeholder}
                />
              </div>
              {item.error && (
                <p className="textLabelRed m-0 mt-2">
                  {(item?.label || "This field") + " is required"}
                </p>
              )}
            </Col>
          );
        }
        if (item?.type === "radio") {
          return (
            <Col lg={item?.width || 12} md={12} sm={12}>
              <div className="inputDiv mt-1">
                <p>
                  {item?.label}
                  {item?.required && <span className="textLabelRed">*</span>}
                </p>
                <div className="d-flex gap-4 checkBoxForm">
                  {item?.options?.length &&
                    item?.options.map((value) => {
                      return (
                        <Form.Check
                          type="radio"
                          label={value.label}
                          name={value.label}
                          checked={item.value === value.value}
                          onChange={() => {
                            let tempForm = [...formArray];
                            tempForm[index].value = value.value;
                            item.required &&
                              (value.value
                                ? (tempForm[index].error = false)
                                : (tempForm[index].error = true));
                            setFormArray([...tempForm]);
                          }}
                        />
                      );
                    })}
                </div>
                {item.error && (
                  <p className="textLabelRed m-0 mt-2">
                    {(item?.label || "This field") + " is required"}
                  </p>
                )}
              </div>
            </Col>
          );
        }
        if (item?.type === "fileupload") {
          return (
            <Col lg={item?.width || 12} md={12} sm={12}>
              <div className="inputDiv mt-1">
                {item?.label && (
                  <p>
                    {item?.label}
                    {item?.required && <span className="textLabelRed">*</span>}
                  </p>
                )}
                <Dropzone onDrop={onDrop} multiple={false}>
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps({
                        className:
                          "dropWrapper d-flex align-items-center justify-content-center",
                      })}
                    >
                      <input {...getInputProps()} />
                      <div className="d-flex justify-content-center flex-wrap">
                        <div className="d-flex gap-2 uploadContent">
                          {fileData[0]?.name && <FileIcon />}
                          {props.uploadPercent > 0 && props.uploadPercent < 100
                            ? `${props.uploadPercent}% Uploaded`
                            : fileData[0]?.name || item.displaycomponent}
                        </div>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
            </Col>
          );
        }
        if (item?.type === "dropdown") {
          return (
            <Col lg={item?.width || 12} md={12} sm={12}>
              <div className="inputDiv mt-1">
                {!props?.isPayment && (
                  <p>
                    {item?.label}
                    {item?.required && <span className="textLabelRed">*</span>}
                  </p>
                )}
                {item.placeholder == "Product Code" ? (<Select
                  className="css-1jqq78o-placeholder"
                  defaultValue={[]}
                  isMulti
                  placeholder={"Product Code"}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={(item) => {
                    clearForm && setClearForm(false);
                    let tempForm = [...formArray];
                    console.log(tempForm, "tempForm>>><<<<<<");
                  //  tempForm[index].value = item.map((data) => data?.value).join(',');
                  tempForm[index].value = item.map((data) => data?.value);
                    console.log(tempForm[index].value,);
                    item.required &&
                      (item?.length > 0
                        ? (tempForm[index].error = false)
                        : (tempForm[index].error = true));
                    setFormArray([...tempForm]);

                    if (Array.isArray(item)) {
                      setSelectedOptions(item.map((opt) => opt.options));
                    }
                  }}
                  // value={clearForm ? "" : item?.value}
                  options={item.options}
                  components={{
                    Option: InputOption
                  }}
                />) :
                  (<select
                    onChange={(e) => {
                      clearForm && setClearForm(false);
                      let tempForm = [...formArray];
                      tempForm[index].value = e.target.value;
                      item.required &&
                        (e.target.value
                          ? (tempForm[index].error = false)
                          : (tempForm[index].error = true));

                      const currElement = tempForm[index]

                      if (currElement.type === 'dropdown' && currElement.label === 'Payment Mode') {
                        const isChequeDD = ['CHEQUE', 'DD'].includes(currElement.value)
                        // currElement.value === 'CHEQUE' ? "CHEQUE" : currElement.value === 'DD' ? 'DD' : '';
                
                        dispatch(setPaymentMode(isChequeDD ? currElement.value : ''));
                
                        const updatedOptions = isChequeDD ? [
                                { value: "allcases", label: "Disbursement" },
                                { value: "Refund", label: "Refund" },
                              ] : [
                                { value: "Vendor", label: "Vendor" },
                                { value: "allcases", label: "Non-Vendor" },
                                { value: "Refund", label: "Refund" },
                              ];
                
                        tempForm.forEach((formElem) => {
                            if (formElem.type === 'dropdown' && formElem.label === "Vendor Type") {
                                formElem.options = updatedOptions;
                            }
                        });
                    }

                      setFormArray([...tempForm]);
                    }}
                    value={clearForm ? "" : item?.value}
                    className="form-select selectInput cursor-pointer"
                  >
                    {item?.placeholder && (
                      <option key={item?.placeholder} hidden value>
                        {item?.placeholder}
                      </option>
                    )}
                    {item?.options?.length &&
                      item?.options.map((value) => {
                        return <option value={value.value}>{value.label}</option>;
                      })}
                  </select>)}
                {item.error && (
                  <p className="textLabelRed m-0 mt-2">
                    {(item?.label || "This field") + " is required"}
                  </p>
                )}
              </div>
            </Col>
          );
        }
        if (item?.type === "customComponent") {
          return (
            <Col lg={6} md={12} sm={12}>
              {item.component}
            </Col>
          );
        }
      });
    }
  };
  const submitForm = () => {
    if (!validate()) {
      let finalData = formArray.map((value) => {
        return {
          [value.label]: value.value,
        };
      });
      props.onSubmit(finalData);
    } else {
    }
  };
  const validate = () => {
    let error = false;
    let tempForm = [...formArray];
    tempForm.map((value) => {
      if (value.required) {
        if (value.value) {
          value.error = false;
        } else {
          value.error = true;
          error = true;
        }
      }
      return value;
    });
    setFormArray([...tempForm]);
    return error;
  };
  const cancelForm = () => {
    if (props?.closeForm) {
      props?.closeForm();
    }
  };
  useEffect(() => {
    if (props.formValues?.length) {
      let tempForm = props.formValues.map((value) => {
        value["error"] = "";
        return value;
      });
      setFormArray([...tempForm]);
    }
  }, [props.formValues]);
  return (
    <>
      <div className="dynamicFormWrapper">
        {/* <Card>
          <Card.Header className="cardHeader">Create Role</Card.Header>
          <Card.Body className="cardBody m-4"> */}
        <Row className="g-3 gx-5 mb-5">{renderForm()}</Row>
        <div className="d-flex gap-4">
          {!props?.isPayment &&
            !props?.hideBtn &&
            !props?.isDownload &&
            !props?.previewBtn && !props.withClearBtn ? (
            <>
              <Button
                className="customButtonGreenInverted"
                onClick={() => cancelForm()}
              >
                Cancel
              </Button>
              <Button
                className="customButtonGreen"
                disabled={props.uploadPercent > 0 && props.uploadPercent < 100}
                onClick={() => submitForm()}
              >
                Submit
              </Button>
            </>
          ) : props?.isDownload ? (
            <>
              <button
                className="customButtonGreen d-flex align-items-center justify-content-center"
                onClick={() => {
                  submitForm();
                }}
              >
                <img src={Download} alt="download" />
                Download
              </button>
            </>
          ) : props?.previewBtn ? (
            <> 
            
              <button
                className="customButtonGreen"
                onClick={() => {
                  submitForm();
                }}
              >
                Preview
              </button>
            </>
          ) : props?.hideBtn ? (
            <></>
          ) : (
            <>
              <button
                className="customButtonGreen"
                onClick={() => submitForm()}
              >
                View Details
              </button>
              <button
                className="customButtonGreenInverted"
                onClick={() => {
                  setClearForm(true);
                  setFormArray(clearFormData());
                }}
              >
                Clear
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default DynamicForm;
