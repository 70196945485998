import React from "react";
import ReactECharts from "echarts-for-react";

const PaymentPieChart = ({ paymentCounts }) => {
  const filteredData = {
    Disbursement: paymentCounts['Disbursement'] || 0,
    Refund: paymentCounts['Refund'] || 0,
  };

  // Convert filtered data to an array of objects for the pie chart
  const pieData = Object.keys(filteredData).map((key) => ({
    name: key,
    value: filteredData[key],
  }));

  // Define chart options
  const chartOptions = {
    title: {
      text: "Payment counts",
      left: "left",
      top: 0,
      textStyle: {
        color: "#36454F",
        fontSize: 14,
        fontWeight: "bold",
      },
    },
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        const { name, value } = params;
        const percentage = (
          (value / pieData.reduce((sum, item) => sum + item.value, 0)) *
          100
        ).toFixed(2);
        return `${name}: ${value} (${percentage}%)`;
      },
    },
    legend: {
      orient: "horizontal",
      left: "left",
      top: 30,
      itemGap: 10,
      itemWidth: 20,
      itemHeight: 14,
      padding: [0, 0, 20, 10],
      textStyle: {
        color: "#36454F",
        fontWeight: "bold",
        fontSize: 12,
      },
    },
    series: [
      {
        name: "Payment Types",
        type: "pie",
        radius: ['40%', '70%'],
        center: ["50%", "70%"],
        startAngle: 180,
        endAngle: 360,
        data: pieData,
        itemStyle: {
          normal: {
            color: (params) => {
              const colors = ["#5470c6", "#91cc75"]; // Disbursement and Refund colors
              return colors[params.dataIndex % colors.length];
            },
            borderRadius: 4,
            borderWidth: 2,
            borderColor: '#fff'
          },
        },
        // emphasis: {
        //   itemStyle: {
        //     shadowBlur: 10,
        //     shadowOffsetX: 0,
        //     shadowColor: "rgba(0, 0, 0, 0.5)",
        //   },
        // },
      },
    ],
    graphic: [
      {
        type: "text",
        left: "center",
        top: "60%",
        style: {
          text: `${filteredData.Disbursement + filteredData.Refund}`,
          fontSize: 18,
          fontWeight: "bold",
          textAlign: "center",
          textVerticalAlign: "middle",
          lineHeight: 30,
          color: "#36454F",
        },
      },
    ],
    color: ["#5470c6", "#91cc75"],
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <div style={{ flex: 1, height: "auto" }}>
        <ReactECharts
          option={chartOptions}
          style={{ height: "500px", width: "100%" }}
        />
      </div>
      <div
        style={{
          backgroundColor: "inherit",
          borderTop: "1px solid #ddd",
        }}
        className="px-4 py-2"
      >
        <div className="d-flex flex-column">
          <div
            style={{ color: "#36454F", fontSize: "16px", fontWeight: "600" }}
            className="mb-3"
          >
            Counts
          </div>
          <div className="d-flex gap-2 flex-wrap">
            {pieData.map((item, index) => (
              <div
                key={index}
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "14px",
                }}
                className="mr-2"
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: chartOptions.color[index % chartOptions.color.length],
                    display: "inline-block",
                    marginRight: "5px",
                    borderRadius: "50%",
                  }}
                ></div>
                <span style={{ fontWeight: "500", color: "#808080" }}>
                  {item.name}:
                </span>
                <span
                  style={{
                    color: "#000000",
                    fontWeight: "700",
                    marginLeft: "5px",
                  }}
                >
                  {item.value}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPieChart;
